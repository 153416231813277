import {
  ICreatePackageDetails
} from '../../../types/esign/createPackage'
import validatePackageDetails from './createPackageDetailsValidator'

/**
 * @function validateAllSteps
 * @description Runs through all the validation functions to check if fully completed
 */

export const validateAllSteps = (createPackage: ICreatePackageDetails) => {
  if (!validateSelectPackage(createPackage)) return false
  if (!validateAddSigners(createPackage)) return false
  if (!validatePackageDetails(createPackage)) return false

  return true
}

/**
 * @function validateSelectPackage
 * @description Checks that there are templates selected
 */

export const validateSelectPackage = (createPackage: ICreatePackageDetails) => {
  return createPackage.packageDetails.templates.length !== 0
}

/**
 * @function validateAddSigners
 * @description Checks that there are signer(s) added to be submitted
 */


export const validateAddSigners = (createPackage: ICreatePackageDetails) => {
  if (createPackage.packageDetails.templates.length === 0) {
    return false
  }
  const templates = createPackage.packageDetails.templates
  for (let i = 0; i < templates.length; i++) {
    const template = templates[i]
    for (let i2 = 0; i2 < template.templateSigners.length; i2++) {
      const signer = template.templateSigners[i2]
      if (signer.isSignerRequired && !signer?.signerEmail) return false
    }
  }
  return true
}
