import CryptoJS from 'crypto-js'

// Note that for larger files, you may want to hash them incrementally.
const md5FromFile = (file: File) => {
  // FileReader is event driven, does not return promise
  // Wrap with promise api so we can call w/ async await
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
  
    reader.onload = (fileEvent: ProgressEvent<FileReader>) => {
      // @ts-ignore
      const binary = CryptoJS.lib.WordArray.create(fileEvent?.target?.result)
      const md5 = CryptoJS.MD5(binary)
      resolve(md5)
    }
    reader.onerror = () => {
      reject('Oops, something went wrong with the file reader.')
    }
    // For some reason, readAsBinaryString(file) does not work correctly,
    // so we will handle it as a word array
    reader.readAsArrayBuffer(file)
  })
}

export const fileChecksum = async(file: File) => {
  const md5: any = await md5FromFile(file)
  return new Promise((resolve, reject) => {
    const checksum = md5.toString(CryptoJS.enc.Base64)
    if(checksum) {
        resolve(checksum)
    }else{
        reject(new Error('Failed to create md5.'))
    }
  })

}
