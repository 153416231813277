import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { UserToken } from 'dt-serverless-ui';
import { selectConfig } from '../global/globalSlice'
import {
  DataIProps
} from './authTypes'
import { IAuthUserData } from '../../../types/Auth';

export interface AuthState {
  currentUser: Record<string, unknown>,
  refreshingToken: boolean,
  isLoggedIn: boolean,
  loading: boolean,
  message: Record<string, unknown> | null
}
const initialState: AuthState = {
  currentUser: {},
  refreshingToken: false,
  isLoggedIn: false,
  loading: false,
  message: null,
}

export const getTokenAsync = createAsyncThunk(
  'auth/getToken',
  async (data: DataIProps, { getState, rejectWithValue  }) => {
    const config: any = selectConfig(getState() as any);
    try {
      const tokenRes = await UserToken.prototype.getToken(data, config);
      return {tokenRes}
    } catch (err:any) {
      return rejectWithValue('Fetch Token: ' + err)
    }

  }
)

export const startLogoutAsync = createAsyncThunk(
  'auth/startLogout',
  async (_, { getState  }) => {
    const config: any = selectConfig(getState() as any);
    const response = await UserToken.prototype.revokeToken(config.baseURL);
    return response;
  }
)


export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginSuccess: (state:any, action: PayloadAction<IAuthUserData>) => {
      state.currentUser = action.payload
      state.isLoggedIn = true
      state.loading = false
      state.message = null
    },
    logOut: (state:any) => {
      state.currentUser = {}
      state.isLoggedIn = false
      state.loading = false
      state.message = null
    },
    clearAuthMessage: (state:any) => {
      state.message = null
    },
    refreshingToken: (state:any, action: PayloadAction<boolean>) => {
      state.refreshingToken = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTokenAsync.pending, (state) => {
        state.loading = true
        state.message = null
      })
      .addCase(getTokenAsync.fulfilled, (state:any, action:any) => {
        state.currentUser = action.payload.tokenRes
        state.isLoggedIn = true
        state.loading = false
        state.message = null
      })
      .addCase(getTokenAsync.rejected, (state:any, action:any) => {
        state.currentUser = {}
        state.isLoggedIn = false
        state.loading = false
        state.message = {type:'error', text:action.payload}
      })
      .addCase(startLogoutAsync.pending, (state) => {
        state.loading = true
      })
      .addCase(startLogoutAsync.fulfilled, (state:any, action:any) => {
        state.currentUser = {}
        state.isLoggedIn = false
        state.loading = false
        state.message = action.payload
      })
      .addCase(startLogoutAsync.rejected, (state:any, action:any) => {
        state.currentUser = {}
        state.isLoggedIn = false
        state.loading = false
        state.message = action.payload
      })
  }
});

export const { loginSuccess, logOut, clearAuthMessage, refreshingToken } = authSlice.actions;
export const selectCurrentUser = (state: RootState) => state.auth.currentUser;

export default authSlice.reducer;
