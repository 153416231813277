import axios from 'axios'
import { tokenExpired, tokenValidator } from 'dt-serverless-ui'
import { jwtDecode } from 'jwt-decode'
import { UserToken } from 'dt-serverless-ui'
import { refreshingToken } from '../../redux-store/auth/authSlice'

/**
 * @description useShortenedTokenExpiration when set to 'true' token refresh time is 2mins. You will also see a warning countdown in the console.
 */

const useShortenedTokenExpiration = false

let store: any

export const injectStore = (_store: any) => {
  store = _store
}

// // Add a request interceptor
const axiosInstance = axios.create()
export const axiosEsignInstance = axios.create()
export const axiosS3Instance = axios.create()
export const axiosVisionWebApiInstance = axios.create()


// Refreshing Poll Logic
export let isRefreshing = false;
export const waitForRefresh = (conditionFunction: any) => {
  const poll = (resolve: any) => {
    if (conditionFunction()) resolve();
    else setTimeout(() => poll(resolve), 100);
  }
  return new Promise(poll);
}

axiosInstance.interceptors.request.use(
  async (config: any) => {
    //  Check & Wait for refresh to finish
    await waitForRefresh(() => !isRefreshing);
    const isTokenExpired = await tokenExpired(useShortenedTokenExpiration)
    let tokens = null
    config.withCredentials = true
    config.headers['X-Content-Type-Options'] = 'nosniff'

    // if null no token was found don't add tokens and continue
    if (isTokenExpired === null) return config

    // Use existing tokens
    tokens = await tokenValidator()

    // Refresh tokens then use refreshed tokens
    if (!isRefreshing && isTokenExpired) {
      isRefreshing = true;
      tokens = await UserToken.prototype.handleTokenRefresh()
      isRefreshing = false;
    }

    if (tokens) {
      config.headers.Authorization = tokens.AccessToken
      config.headers.Authentication = tokens.IdToken
      return config
    }

    return config
  },
  error => {
    Promise.reject(error)
  }
)

axiosEsignInstance.interceptors.request.use(
  async (config: any) => {
    const tokens = await tokenValidator()
    config.withCredentials = true

    // Attaching token to headers for authentication on backend
    if (tokens) {
      const tokenDecoded: any = jwtDecode(tokens.AccessToken)
      const expiry = tokenDecoded.exp
      const now = new Date()
      // Check Expiry
      if (now.getTime() < expiry * 1000) {
        config.headers.Authorization = `Bearer ${tokens.IdToken}`
        config.headers['X-Content-Type-Options'] = 'nosniff'
      } else {
        const tokensRefreshed: any = await UserToken.prototype.handleTokenRefresh()
        config.headers.Authorization = `Bearer ${tokensRefreshed.IdToken}`
        config.headers['X-Content-Type-Options'] = 'nosniff'
        return config
      }
    }
    return config
  },
  error => {
    Promise.reject(error)
  }
)

axiosVisionWebApiInstance.interceptors.request.use(
  async (config: any) => {
    const tokens = await tokenValidator()
    config.withCredentials = true

    // Attaching token to headers for authentication on backend
    if (tokens) {
      const tokenDecoded: any = jwtDecode(tokens.AccessToken)
      const expiry = tokenDecoded.exp
      const now = new Date()
      // Check Expiry
      if (now.getTime() < expiry * 1000) {
        config.headers.Authorization = `Bearer ${tokens.IdToken}`
        config.headers['X-Content-Type-Options'] = 'nosniff'
      } else {
        const tokensRefreshed: any = await UserToken.prototype.handleTokenRefresh()
        config.headers.Authorization = `Bearer ${tokensRefreshed.IdToken}`
        config.headers['X-Content-Type-Options'] = 'nosniff'
        return config
      }
    }
    return config
  }
)

axiosS3Instance.interceptors.request.use(
  async (config: any) => {
    const tokens = await tokenValidator()
    config.responseType = "blob"

    // Attaching token to headers for authentication on backend
    if (tokens) {
      const tokenDecoded: any = jwtDecode(tokens.AccessToken)
      const expiry = tokenDecoded.exp
      const now = new Date()
      // Check Expiry
      if (now.getTime() < expiry * 1000) {
        config.headers['X-Bearer-Auth'] = tokens.IdToken
        config.headers['X-Content-Type-Options'] = 'nosniff'
      } else {
        const tokensRefreshed: any = await UserToken.prototype.handleTokenRefresh()
        config.headers['X-Bearer-Auth'] = tokensRefreshed.IdToken
        config.headers['X-Content-Type-Options'] = 'nosniff'
        return config
      }
    }
    return config
  },
  error => {
    Promise.reject(error)
  }
)

export async function checkToken() {
  const isTokenExpired = await tokenExpired()
  const isRefreshingToken = store.getState().auth.refreshingToken
  if (!isTokenExpired && !isRefreshingToken) {
    return true
  } else {
    const baseURL = window.__env.baseURL
    await store.dispatch(refreshingToken(true))
    UserToken.prototype.refreshToken(baseURL)
      .then(() => {
        store.dispatch(refreshingToken(false))
        return true
      })
  }
  if (!isRefreshingToken) {
    checkToken()
  }

  return false
}

export default axiosInstance
