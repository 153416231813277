import { Box, Button, Typography, TextField, FormControl, Switch, FormControlLabel, FormHelperText, Paper, Grow, Alert } from "@mui/material";
import { ICreatePackageDetails } from "../../../../types/esign/createPackage";
import { FormikValues, useFormik } from "formik";
import * as yup from 'yup';
import AddSignerKBADialog from "../AddSignerKBADialog";
import { useEffect, useState } from "react";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { TodayOutlined } from "@mui/icons-material";
import KBASignerCard from "../KBASignerCard";
import moment from 'moment-timezone'
import MFAPhoneCard from "../MFAPhoneCard";
import AddSignerDialog from "../AddSignerDialog";
import validatePackageDetails from "../../utils/createPackageDetailsValidator";
import StepperHeader from "../../../shared/components/StepperHeader";
import { defaultTimezone } from "../../utils/consts";
import { DTInfoPopover } from "dt-serverless-ui";


interface IProps {
  backStep?: () => void;
  nextStep?: () => void;
  data: ICreatePackageDetails
  updatePackage: any
}

const infoTextObj: any = {
  eSignExp: 'The last day a signer may eSign before the signing link expires.',
  sessionExp: 'The last day a signer may access the eSign link to download / print documents.',
  enableMFA: 'MFA (Multi Factor Authentication) will prompt your signer to either receive a text message or call at the number you provide. This security ensures it was received by the right individual.',
  enableKBA: 'KBA (Knowledge Based Authentication) will prompt your signer to answer authentication questions you provide. This security ensures it was received by the right individual.'
}

export default function CPPackageDetails(props: IProps) {
  const { backStep, nextStep, data, updatePackage } = props
  const [targetSignerUpdateObject, setTargetSignerUpdateObject] = useState<null | any>(null)
  const [targetSignerKBAObject, setTargetSignerKBAObject] = useState<null | any>(null)
  const [openDPEsign, setOpenDPEsign] = useState(false)
  const [openDPSession, setOpenDPSession] = useState(false)

  const validationSchema = yup.object({
    packageName: yup.string()
      .min(3, 'Please a Package Name')
      .required('Please a Package2 Name'),
    eSignExp: yup.date()
      .nullable()
      .required('Date is required'),
    sessionExp: yup.date()
      .nullable()
      .required('Date is required'),
    enableMFA: yup.boolean(),
    enableKBA: yup.boolean()
  });

  moment.tz.setDefault(defaultTimezone);

  const formik = useFormik({
    initialValues: {
      packageName: (data.packageDetails.packageName) ? data.packageDetails.packageName : '',
      eSignExp: (data.packageDetails.eSignExp) ? moment(data.packageDetails.eSignExp) : null,
      sessionExp: (data.packageDetails.sessionExp) ? moment(data.packageDetails.sessionExp) : null,
      enableMFA: (data.packageDetails.enableMFA),
      enableKBA: (data.packageDetails.enableKBA)
    } as FormikValues,
    validationSchema: validationSchema,
    onSubmit: () => {
      // handle submit not needed
    }
  });

  useEffect(() => {
    handleFormUpdateRedux()
  }, [formik.values])

  const handleFormUpdateRedux = () => {
    const update: ICreatePackageDetails = JSON.parse(JSON.stringify(data))

    // Note: Expiry dates should be at the end of the day. The datepicker defaults to 00:00 in the currently selected timezone. If moment.tz.setDetault is not used the browser's timezone will be used
    const eSignExpValue = moment(formik.values.eSignExp).add(1, 'day').subtract(1, 'second').toISOString();
    const sessionExpValue = moment(formik.values.sessionExp).add(1, 'day').subtract(1, 'second').toISOString();

    update.packageDetails.consentWindow = (formik.values.eSignExp) ? eSignExpValue : null
    update.packageDetails.eSignExp = (formik.values.eSignExp) ? eSignExpValue : null
    update.packageDetails.sessionExp = (formik.values.sessionExp) ? sessionExpValue : null
    update.packageDetails.enableMFA = formik.values.enableMFA
    update.packageDetails.enableKBA = formik.values.enableKBA
    update.packageDetails.packageName = formik.values.packageName

    updatePackage(update)
  };

  const handleUpdateKBAQuestions = (vals: any) => {
    const update: ICreatePackageDetails = JSON.parse(JSON.stringify(data))
    if (update.packageDetails.kbaQuestions.length === 0) {
      // Add new signer KBA Object
      update.packageDetails.kbaQuestions.push({ email: targetSignerKBAObject.email, questions: vals })
    } else {
      for (let i = 0; i < update.packageDetails.kbaQuestions.length; i++) {
        const kbaObject = update.packageDetails.kbaQuestions[i];
        if (kbaObject.email === targetSignerKBAObject.email) {

          if (vals.length !== 0) {
            update.packageDetails.kbaQuestions[i].questions = vals
          } else {
            update.packageDetails.kbaQuestions.splice(i, 1)
          }

        } else {
          // Add new signer KBA Object
          update.packageDetails.kbaQuestions.push({ email: targetSignerKBAObject.email, questions: vals })

        }
      }
    }
    setTargetSignerKBAObject(null)
    updatePackage(update)
  }

  const handleBack = () => {
    if (backStep) {
      backStep()
    }
  }

  const handleNext = () => {
    if (nextStep) {
      nextStep()
    }
  }

  const handleUpdateSigner = (vals: any) => {
    const update: ICreatePackageDetails = JSON.parse(JSON.stringify(data))
    const signerIndex: number = update.signersList.findIndex((signer) => signer.email === vals.email)
    if (signerIndex !== -1) {
      update.signersList[signerIndex].phoneNumber = vals.phoneNumber
      updatePackage(update)
      setTargetSignerUpdateObject(null)
    }
  }


  return (
    <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
      <Box sx={{ padding: '15px', maxWidth: '550px', width: '100%' }}>

        {(targetSignerUpdateObject) &&
          <AddSignerDialog
            open={true}
            edit={true}
            addedSigners={data.signersList}
            signer={(targetSignerUpdateObject) ? targetSignerUpdateObject : null}
            close={() => {
              setTargetSignerUpdateObject(null)
            }}
            submit={(vals: any) => handleUpdateSigner(vals)}
          />
        }


        {(targetSignerKBAObject) &&
          <AddSignerKBADialog
            open={true}
            headerText={`${targetSignerKBAObject.firstName}'s Security Questions`}
            questions={() => {
              const qs = data?.packageDetails?.kbaQuestions?.find((q: any) => q.email === targetSignerKBAObject.email)
              if (qs) return qs.questions
              return []
            }}
            close={() => setTargetSignerKBAObject(null)}
            submit={(vals: any) => handleUpdateKBAQuestions(vals)}
          />
        }

        <StepperHeader
          title='Enter Package Details'
        />

        <form>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>

            <TextField
              fullWidth
              required
              margin="dense"
              type="string"
              variant="standard"
              id="packageName"
              name="packageName"
              label="Package Name"
              value={formik.values.packageName}
              onChange={formik.handleChange}
              onBlur={(e) => formik.setFieldValue('packageName', e.target.value)}
              error={formik.touched.packageName && Boolean(formik.errors.packageName)}
            />

            <FormControl
              fullWidth
              margin='normal'
            >
              <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                <DTInfoPopover infoText={(infoTextObj['eSignExp'])} iconSize={'medium'}/>
                <LocalizationProvider dateAdapter={AdapterMoment} dateLibInstance={moment}>
                  <MobileDatePicker

                    disablePast
                    closeOnSelect
                    label='eSign Expiration *'
                    value={formik.values.eSignExp}
                    open={openDPEsign}
                    onOpen={() => setOpenDPEsign(true)}
                    onClose={() => setOpenDPEsign(false)}
                    onChange={(value: any) => formik.setFieldValue('eSignExp', value)}
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        variant: 'standard',
                        id: 'eSignExp',
                        InputProps: {
                          endAdornment: <TodayOutlined />

                        }
                      },

                    }}
                  />
                </LocalizationProvider>
              </Box>
            </FormControl>

            <FormControl
              fullWidth
              margin='normal'
            >
              <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                <DTInfoPopover infoText={(infoTextObj['sessionExp'])} iconSize={"medium"}/>
                <LocalizationProvider dateAdapter={AdapterMoment} dateLibInstance={moment}>
                  <MobileDatePicker

                    disablePast
                    closeOnSelect
                    label='Doc Link Expiration *'
                    value={formik.values.sessionExp}
                    open={openDPSession}
                    onOpen={() => setOpenDPSession(true)}
                    onClose={() => setOpenDPSession(false)}
                    onChange={(value: any) => formik.setFieldValue('sessionExp', value)}
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        variant: 'standard',
                        id: 'sessionExp',
                        InputProps: {
                          endAdornment: <TodayOutlined />

                        }
                      },

                    }}
                  />
                </LocalizationProvider>
              </Box>
            </FormControl>

            <FormControl
              fullWidth
              margin='normal'
            >
              <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                <DTInfoPopover infoText={(infoTextObj['enableMFA'])} iconSize={'medium'}/>
                <FormControlLabel
                  checked={formik.values.enableMFA}
                  control={<Switch color="primary" />}
                  label="Enable MFA"
                  labelPlacement="start"
                  onChange={(e: any) => formik.setFieldValue('enableMFA', e.target.checked)}
                  sx={{
                    width: '100%',
                    marginLeft: '0px'
                  }}
                  slotProps={{
                    typography: {
                      sx: { width: '100%', textAlign: 'left' }
                    }
                  }}
                />
              </Box>
            </FormControl>

            <Grow
              in={formik.values.enableMFA}
              {...(formik.values.enableMFA ? { timeout: 500 } : { timeout: 0 })}
              style={{ transformOrigin: '50% 0 0', height: (formik.values.enableMFA) ? 'auto' : '0px', padding: (formik.values.enableMFA) ? undefined : '0px' }}
            >

              <Paper elevation={1} sx={{ padding: (theme) => theme.spacing(2) }}>
                {(
                  data.packageDetails.enableMFA &&
                  data.signersList.find((signer) => signer.phoneNumber === '')
                ) &&
                  <Alert sx={{ marginBottom: (theme) => theme.spacing(2) }} variant='standard' severity="error">MFA Requires all signers have a valid phone number.</Alert>
                }
                {data.signersList.map((signer, index) =>
                  <Box
                    key={index}
                    sx={{
                      marginBottom: (data.signersList.length !== index + 1) ? (theme: any) => theme.spacing(2) : '0px'
                    }}
                  >
                    <MFAPhoneCard
                      firstName={signer.firstName}
                      lastName={signer.lastName}
                      phoneNumber={signer.phoneNumber}
                      onClick={() => setTargetSignerUpdateObject(signer)}
                    />
                  </Box>

                )}
              </Paper>
            </Grow>


            <FormControl
              fullWidth
              margin='normal'
            >
              <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
                <DTInfoPopover infoText={(infoTextObj['enableKBA'])} iconSize={'medium'}/>
                <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', marginRight: '-10px'}}>
                  <FormControlLabel
                    checked={formik.values.enableKBA}
                    control={<Switch color="primary" />}
                    label="Enable KBA"
                    labelPlacement="start"
                    onChange={(e: any) => formik.setFieldValue('enableKBA', e.target.checked)}
                    sx={{
                      width: '100%',
                      marginLeft: '0px'
                    }}
                    slotProps={{
                      typography: {
                        sx: { width: '100%', textAlign: 'left' }
                      }
                    }}
                  />
                  <FormHelperText sx={{ marginTop: '0px', marginLeft: '0px' }}>Security Questions are optional.</FormHelperText>
                </Box>
              </Box>
            </FormControl>
          </Box>
        </form>
        <Grow
          in={formik.values.enableKBA}
          {...(formik.values.enableKBA ? { timeout: 500 } : { timeout: 0 })}
          style={{ transformOrigin: '50% 0 0', height: (formik.values.enableKBA) ? 'auto' : '0px' }}
        >

          <Paper elevation={1} sx={{ padding: (theme) => theme.spacing(2) }}>
            <Typography sx={{ paddingBottom: (theme: any) => theme.spacing(1) }} variant='subtitle2'>Add Optional Security Questions</Typography>
            {data.signersList.map((signer, index) =>
              <Box
                key={index}
                sx={{
                  marginBottom: (data.signersList.length !== index + 1) ? (theme: any) => theme.spacing(2) : '0px'
                }}
              >
                <KBASignerCard
                  firstName={signer.firstName}
                  lastName={signer.lastName}
                  questions={(() => {
                    const qs = data?.packageDetails?.kbaQuestions?.find((q: any) => q.email === signer.email)
                    if (qs) return qs.questions
                    return []
                  })()}
                  onClick={() => setTargetSignerKBAObject(signer)}
                />
              </Box>

            )}
          </Paper>
        </Grow>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginTop: (theme) => theme.spacing(2)
          }}
        >
          <Button variant='outlined' color='primary' onClick={() => handleBack()}>Back</Button>
          <Button variant='contained' color='primary' disabled={!validatePackageDetails(data)} onClick={() => handleNext()}>Next</Button>
        </Box>

      </Box>
    </Box>

  )
}
