import axiosInterceptor, { axiosEsignInstance } from '../../shared/utils/axios'
import axios from 'axios'
import { transformJsonKeyCasing } from '../../shared/utils/helpers'
/**
 * @param {string} query string of query params to be used in API path
 * exampleAPI path: /sessions?Page=1&PageCount=10&Statuses=underwriting
 * @param {object} config localConfig 
 * Categories:
 * =============
 * closing
 * preclosing
 * disclosure
 * predisclosure
 * processing
 * adverseaction
 * redisclosure
 * underwriting
 * shipping
 * verification
 * @returns {Promise} array of sessions 
 */
export const fetchSessionsByCategory = (query: string, config: any) => {
  return new Promise<any>((resolve, reject) => {
    // Page & PageCount
    const url = config.baseURL + '/sessions?' + query
    axiosInterceptor.get(url)
      .then((response: any) => {
        resolve({
          sessions: response.data.Sessions,
          totalCount: response.data.TotalCount
        })
      })
      .catch((error: any) => {
        if (axios.isCancel(error)) {
          // Request canceled...
          // resolve('')
        } else {
          let errorText:any = 'Something Went Wrong'
          if (error.message) { errorText = error.message }
          if (error.Message) { errorText = error.Message }
          if (error?.response?.data?.Message) { errorText = error.message }
          reject(errorText)
        }
      })
  })
}

/**
 *
 * @param {string} query string of query params to be used in API path
 * example API path: /sessions/search?TermTypes=LoanNumber&Terms=jb_te&page=0&PageCount=10
 * @param cancelToken 
 * @param {object} config localConfig 
 * @returns {Promise} array of sessions
 */
export const searchSession = (query: string, cancelToken:any, config: any) => {
  return new Promise<any>((resolve, reject) => {
    const axiosConfig: any = {
      method: 'get',
      url: config.baseURL + '/sessions/search?' + query,
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json'
      },
      cancelToken: cancelToken
    }

    axiosInterceptor(axiosConfig)
      .then((response: any) => {
        resolve({
          sessions: response.data.Sessions,
          totalCount: response.data.TotalCount
        })
      })
      .catch((error: any) => {
        if (axios.isCancel(error)) {
          // Request canceled...
          // resolve('')
        } else {
          let errorText:any = 'Something Went Wrong'
          if (error.message) { errorText = error.message }
          if (error.Message) { errorText = error.Message }
          if (error?.response?.data?.Message) { errorText = error.message }
          reject(errorText)
        }
      })
  })
}

export const fetchSessionsAccessUsers = (config: any) => {
  return new Promise<any>((resolve, reject) => {
    const url = config.baseURL + '/v2/api/session/users'

    axiosEsignInstance.get(url)
      .then((response: any) => {
        const users = response.data.result.map((r:any) => r.emailAddress.toLowerCase())
        resolve({
          users: users
        })
      })
      .catch((error: any) => {
        if (axios.isCancel(error)) {
          // Request canceled...
          // resolve('')
        } else {
          let errorText:any = 'Something Went Wrong'
          if (error.message) { errorText = error.message }
          if (error.Message) { errorText = error.Message }
          if (error?.response?.data?.Message) { errorText = error.message }
          reject(errorText)
        }
      })
  })
}

export const fetchSessionsByUserEmail = (email: string | null, config: any) => {
  return new Promise<any>((resolve, reject) => {
    const url = config.baseURL + '/v2/api/session/user-sessions?UserEmail=' + email

    axiosEsignInstance.get(url)
      .then((response:any) => {
        const convertedSessionsObj = transformJsonKeyCasing(response.data.result.sessions, 'pascal')
        resolve({
          userEmail: email,
          sessions: convertedSessionsObj,
          totalCount: response.data.result.totalCount
        })
      })
      .catch((error: any) => {
        if (axios.isCancel(error)) {
          // Request canceled...
          // resolve('')
        } else {
          let errorText:any = 'Something Went Wrong'
          if (error.message) { errorText = error.message }
          if (error.Message) { errorText = error.Message }
          if (error?.response?.data?.Message) { errorText = error.message }
          reject(errorText)
        }
      })
  })
}