import { ISession } from "../../../../types/Session"

export const mockDefaultSessionsData: ISession[] = [
  {
    label: 'Pre Disclosure',
    value: 'pre_disclosure',
    loaded: false,
    checked: true,
    data: null,
    error: null,
    page: 0,
    hasNextPage: true,
    isNextPageLoading: false
  },
  {
    label: 'Initial Disclosure',
    value: 'disclosure',
    loaded: false,
    checked: true,
    data: null,
    error: null,
    page: 0,
    hasNextPage: true,
    isNextPageLoading: false
  },
  {
    label: 'Re Disclosure',
    value: 'redisclosure,tla_redisclosure',
    loaded: false,
    checked: true,
    data: null,
    error: null,
    page: 0,
    hasNextPage: true,
    isNextPageLoading: false
  },
  {
    label: 'Adverse Action',
    value: 'adverse_action',
    loaded: false,
    checked: true,
    data: null,
    error: null,
    page: 0,
    hasNextPage: true,
    isNextPageLoading: false
  },
  {
    label: 'Appraisal',
    value: 'appraisal',
    loaded: false,
    checked: true,
    data: null,
    error: null,
    page: 0,
    hasNextPage: true,
    isNextPageLoading: false
  },
  {
    label: 'Processing',
    value: 'processing',
    loaded: false,
    checked: true,
    data: null,
    error: null,
    page: 0,
    hasNextPage: true,
    isNextPageLoading: false
  },
  {
    label: 'Underwriting',
    value: 'underwriting',
    loaded: false,
    checked: true,
    data: null,
    error: null,
    page: 0,
    hasNextPage: true,
    isNextPageLoading: false
  },
  {
    label: 'Pre Closing',
    value: 'preclosing',
    loaded: false,
    checked: true,
    data: null,
    error: null,
    page: 0,
    hasNextPage: true,
    isNextPageLoading: false
  },
  {
    label: 'Closing',
    value: 'closing',
    loaded: false,
    checked: true,
    data: null,
    error: null,
    page: 0,
    hasNextPage: true,
    isNextPageLoading: false
  },
  {
    label: 'Shipping',
    value: 'shipping',
    loaded: false,
    checked: true,
    data: null,
    error: null,
    page: 0,
    hasNextPage: true,
    isNextPageLoading: false
  }
]

export const mockSessionsData = [
  {
    label: 'Closing',
    value: 'closing',
    loaded: true,
    checked: true,
    data: [
      {
        SessionId: 'E198E85B-1FB4-4ECE-BB3E-20809E55A7AF',
        SignerNames: [],
        Property: '175 13th Street Washington, DC 20013',
        LoanNumber: '6130135745',
        Purpose: 'No Cash Out Other',
        Status: 'closing',
        CreatedDate: '0001-01-01T00:00:00',
        ClosingDate: "2024-03-24T17:51:06.137",
        ClientId: 1063,
        ClientCode: 'DOCU'
      },
      {
        SessionId: '492745CF-A72B-48EC-9ACC-1CA59250AE35',
        SignerNames: [
          'JOHN HOMEOWNER',
          'KEN CUSTOMER',
          'BARBIE CUSTOMER',
          'JACKIE HOMEOWNER',
          'Settlement Agent'
        ],
        Property: '654684984894987494 N Chevy St 181564984 New York, NY 10001',
        LoanNumber: '6130135599',
        Purpose: 'Purchase',
        Status: 'closing',
        CreatedDate: '0001-01-01T00:00:00',
        ClosingDate: "2024-03-24T17:51:06.137",
        ClientId: 1005,
        ClientCode: 'C704'
      },
      {
        SessionId: 'EC72E12A-81E9-4A0D-ACC0-6A17F39F7248',
        SignerNames: [
          'John Homeowner'
        ],
        Property: '654684984894987494 N Chevy St 181564984 New York, NY 10001',
        LoanNumber: '6130135599',
        Purpose: 'Purchase',
        Status: 'closing',
        CreatedDate: '0001-01-01T00:00:00',
        ClosingDate: "2024-03-24T17:51:06.137",
        ClientId: 1005,
        ClientCode: 'C704'
      },
      {
        SessionId: '20342605-6B25-419C-91F1-41588D5A6381',
        SignerNames: [],
        Property: '175 13th Street Washington, DC 20013',
        LoanNumber: '6160293025',
        Purpose: 'No Cash Out Other',
        Status: 'closing',
        CreatedDate: '0001-01-01T00:00:00',
        ClosingDate: "2024-03-24T17:51:06.137",
        ClientId: 1063,
        ClientCode: 'DOCU'
      },
      {
        SessionId: '1169001A-986D-4943-9C92-EF2F8B3F17D1',
        SignerNames: [],
        Property: '175 13th Street Washington, DC 20013',
        LoanNumber: '6160293025',
        Purpose: 'No Cash Out Other',
        Status: 'closing',
        CreatedDate: '0001-01-01T00:00:00',
        ClosingDate: "2024-03-24T17:51:06.137",
        ClientId: 1063,
        ClientCode: 'DOCU'
      },
      {
        SessionId: '7E622AE8-169F-4EFD-8206-DC208E590447',
        SignerNames: [],
        Property: '1226 460th Fox River Grv, IL 60021',
        LoanNumber: '4olotest3200054366_2',
        Purpose: 'No Cash Out Other',
        Status: 'closing',
        CreatedDate: '0001-01-01T00:00:00',
        ClosingDate: "2024-03-24T17:51:06.137",
        ClientId: 1063,
        ClientCode: 'DOCU'
      },
      {
        SessionId: 'C61C50C8-F0D4-49B2-8AF6-55C359DCA639',
        SignerNames: [
          'BORROWER1FIRST BORROWER1LAST',
          'Witness 1',
          'Witness 2',
          'NOTARY PARTY',
          'George Settlement'
        ],
        Property: '1000 N Main St Clearwater, FL 33755',
        LoanNumber: 'BVTM_01TEST_PAVASO_181',
        Purpose: 'Purchase',
        Status: 'closing',
        CreatedDate: '0001-01-01T00:00:00',
        ClosingDate: "2024-03-24T17:51:06.137",
        ClientId: 927,
        ClientCode: 'QA07'
      },
      {
        SessionId: 'D887B728-A687-4C7B-AB63-FC3DDCB45545',
        SignerNames: [
          'BORROWER1FIRST BORROWER1LAST',
          'Witness 1',
          'Witness 2',
          'NOTARY PARTY',
          'George Settlement'
        ],
        Property: '1000 N Main St Clearwater, FL 33755',
        LoanNumber: 'BVTM_01TEST_NOTARYCAM_182',
        Purpose: 'Purchase',
        Status: 'closing',
        CreatedDate: '0001-01-01T00:00:00',
        ClosingDate: "2024-03-24T17:51:06.137",
        ClientId: 927,
        ClientCode: 'QA07'
      },
      {
        SessionId: '9867B566-FAF5-47B2-A073-702AD1ADEB45',
        SignerNames: [
          'BORROWER1FIRSTNAME BORROWER1LASTNAME',
          'NOTARY PARTY',
          'Witness 1',
          'Witness 2',
          'Settlement Agent'
        ],
        Property: '1000 N Main St Vienna, VA 22180',
        LoanNumber: 'BVTM_01TEST_NOTARIZE_183',
        Purpose: 'Purchase',
        Status: 'closing',
        CreatedDate: '0001-01-01T00:00:00',
        ClosingDate: "2024-03-24T17:51:06.137",
        ClientId: 927,
        ClientCode: 'QA07'
      },
      {
        SessionId: 'FAF1614F-5910-4686-B69D-D7724F632BBA',
        SignerNames: [],
        Property: '39 Grove ave Lockport, NY 14094',
        LoanNumber: '6027292360',
        Purpose: 'Purchase',
        Status: 'closing',
        CreatedDate: '0001-01-01T00:00:00',
        ClosingDate: "2024-03-24T17:51:06.137",
        ClientId: 1063,
        ClientCode: 'DOCU'
      }
    ],
    error: null,
    page: 1,
    hasNextPage: true,
    isNextPageLoading: false,
    totalCount: 4259
  },
  {
    label: 'Pre Closing',
    value: 'preclosing',
    loaded: true,
    checked: true,
    data: [
      {
        SessionId: '27B007A3-9955-4391-96E2-71DDFE4C1197',
        SignerNames: [
          'Alice Firstimer'
        ],
        Property: '9991 Warford Street Dawson, IA 50066',
        LoanNumber: 'QA4540607',
        Purpose: 'Purchase',
        Status: 'preclosing',
        CreatedDate: '2022-10-01T00:00:00',
        ClosingDate: "2024-03-24T17:51:06.137",
        ClientId: 132,
        ClientCode: 'EMPW'
      },
      {
        SessionId: '556B0CE4-4C9D-44DD-9120-4AA0E063CBD5',
        SignerNames: [
          'Alice Firstimer'
        ],
        Property: '9991 Warford Street Dawson, IA 50066',
        LoanNumber: 'QA4543301',
        Purpose: 'Purchase',
        Status: 'preclosing',
        CreatedDate: '2022-10-01T00:00:00',
        ClosingDate: "2024-03-24T17:51:06.137",
        ClientId: 132,
        ClientCode: 'EMPW'
      },
      {
        SessionId: '6084D8FE-1717-4F78-A3AA-BC6CD26CA4F1',
        SignerNames: [
          'Alice Firstimer'
        ],
        Property: '9991 Warford Street Dawson, IA 50066',
        LoanNumber: 'QA4549548',
        Purpose: 'Purchase',
        Status: 'preclosing',
        CreatedDate: '2022-10-01T00:00:00',
        ClosingDate: "2024-03-24T17:51:06.137",
        ClientId: 132,
        ClientCode: 'EMPW'
      },
      {
        SessionId: '858BE1CD-DDF2-4CE4-9650-71335B24986B',
        SignerNames: [
          'Alice Firstimer'
        ],
        Property: '9991 Warford Street Dawson, IA 50066',
        LoanNumber: 'QA4549555',
        Purpose: 'Purchase',
        Status: 'preclosing',
        CreatedDate: '2022-10-01T00:00:00',
        ClosingDate: "2024-03-24T17:51:06.137",
        ClientId: 132,
        ClientCode: 'EMPW'
      },
      {
        SessionId: 'A2FF7E21-F0B2-4F3C-ADD6-EEF49D069DC5',
        SignerNames: [
          'Alice Firstimer'
        ],
        Property: '9991 Warford Street Dawson, IA 50066',
        LoanNumber: 'QA4590408',
        Purpose: 'Purchase',
        Status: 'preclosing',
        CreatedDate: '2022-10-01T00:00:00',
        ClosingDate: "2024-03-24T17:51:06.137",
        ClientId: 132,
        ClientCode: 'EMPW'
      },
      {
        SessionId: '3546E61D-2C86-4FE2-8620-6338E638D8A2',
        SignerNames: [
          'Alice Firstimer'
        ],
        Property: '9991 Warford Street Dawson, IA 50066',
        LoanNumber: 'QA4590436',
        Purpose: 'Purchase',
        Status: 'preclosing',
        CreatedDate: '2022-10-01T00:00:00',
        ClosingDate: "2024-03-24T17:51:06.137",
        ClientId: 132,
        ClientCode: 'EMPW'
      },
      {
        SessionId: 'B56E6A0E-4E44-4A20-AA08-B4C0B9789EBF',
        SignerNames: [
          'Alice Firstimer'
        ],
        Property: '9991 Warford Street Dawson, IA 50066',
        LoanNumber: 'QA4590446',
        Purpose: 'Purchase',
        Status: 'preclosing',
        CreatedDate: '2022-10-01T00:00:00',
        ClosingDate: "2024-03-24T17:51:06.137",
        ClientId: 132,
        ClientCode: 'EMPW'
      },
      {
        SessionId: '9A9BBD4F-9789-4421-9188-454BC2E96A01',
        SignerNames: [
          'Alice Firstimer'
        ],
        Property: '9991 Warford Street Dawson, IA 50066',
        LoanNumber: 'QA4590470',
        Purpose: 'Purchase',
        Status: 'preclosing',
        CreatedDate: '2022-10-01T00:00:00',
        ClosingDate: "2024-03-24T17:51:06.137",
        ClientId: 132,
        ClientCode: 'EMPW'
      },
      {
        SessionId: '4F050F00-F9E2-402C-B587-9E498889319D',
        SignerNames: [
          'Alice Firstimer'
        ],
        Property: '9991 Warford Street Dawson, IA 50066',
        LoanNumber: 'QA4590476',
        Purpose: 'Purchase',
        Status: 'preclosing',
        CreatedDate: '2022-10-01T00:00:00',
        ClosingDate: "2024-03-24T17:51:06.137",
        ClientId: 132,
        ClientCode: 'EMPW'
      },
      {
        SessionId: '5092EC9B-89C3-4F19-BD84-4696D01AB231',
        SignerNames: [
          'Alice Firstimer'
        ],
        Property: '9991 Warford Street Dawson, IA 50066',
        LoanNumber: 'QA4582767',
        Purpose: 'Purchase',
        Status: 'preclosing',
        CreatedDate: '2022-10-01T00:00:00',
        ClosingDate: "2024-03-24T17:51:06.137",
        ClientId: 132,
        ClientCode: 'EMPW'
      }
    ],
    error: null,
    page: 1,
    hasNextPage: true,
    isNextPageLoading: false,
    totalCount: 3728
  },
  {
    label: 'Initial Disclosure',
    value: 'disclosure',
    loaded: true,
    checked: true,
    data: [
      {
        SessionId: 'AEA1F9CC-D21C-4530-96F6-56F089FF0FAB',
        SignerNames: [
          'Alice Firstimer',
          'Test Loan Origination',
          'Test Loan Origination',
          'Test Loan Origination'
        ],
        Property: '9991 Warford Street Dawson, IA 50066',
        LoanNumber: 'QA4602210',
        Purpose: 'Purchase',
        Status: 'disclosure',
        CreatedDate: '2022-10-01T00:00:00',
        ClosingDate: "2024-03-24T17:51:06.137",
        ClientId: 132,
        ClientCode: 'EMPW'
      },
      {
        SessionId: '9FBBF548-6924-455C-9D6B-2D751DE2E86E',
        SignerNames: [
          'Alice Firstimer',
          'Test Loan Origination',
          'Test Loan Origination',
          'Test Loan Origination'
        ],
        Property: '9991 Warford Street Dawson, IA 50066',
        LoanNumber: 'QA4602255',
        Purpose: 'Purchase',
        Status: 'disclosure',
        CreatedDate: '2022-10-01T00:00:00',
        ClosingDate: "2024-03-24T17:51:06.137",
        ClientId: 132,
        ClientCode: 'EMPW'
      },
      {
        SessionId: 'EB4F86DC-AE46-4EB7-ACF2-C632092B60B0',
        SignerNames: [
          'Alice Firstimer',
          'Test Loan Origination',
          'Test Loan Origination'
        ],
        Property: '9991 Warford Street Dawson, IA 50066',
        LoanNumber: 'QA4602340',
        Purpose: 'Purchase',
        Status: 'disclosure',
        CreatedDate: '2022-10-01T00:00:00',
        ClosingDate: "2024-03-24T17:51:06.137",
        ClientId: 132,
        ClientCode: 'EMPW'
      },
      {
        SessionId: 'EB4BB82C-0037-49D0-B127-2FBEC1607F76',
        SignerNames: [
          'Alice Firstimer',
          'Test Loan Origination',
          'Test Loan Origination',
          'Test Loan Origination'
        ],
        Property: '9991 Warford Street Dawson, IA 50066',
        LoanNumber: 'QA4600452',
        Purpose: 'Purchase',
        Status: 'disclosure',
        CreatedDate: '2022-10-01T00:00:00',
        ClosingDate: "2024-03-24T17:51:06.137",
        ClientId: 132,
        ClientCode: 'EMPW'
      },
      {
        SessionId: '8D045122-3E5B-4ED1-B68F-0BBBEB742CF1',
        SignerNames: [
          'Alice Firstimer',
          'Test Loan Origination',
          'Test Loan Origination'
        ],
        Property: '9991 Warford Street Dawson, IA 50066',
        LoanNumber: 'QA4600463',
        Purpose: 'Purchase',
        Status: 'disclosure',
        CreatedDate: '2022-10-01T00:00:00',
        ClosingDate: "2024-03-24T17:51:06.137",
        ClientId: 132,
        ClientCode: 'EMPW'
      },
      {
        SessionId: '5A186CB8-198B-453D-A763-1FD5999F53B4',
        SignerNames: [
          'Alice Firstimer',
          'Test Loan Origination',
          'Test Loan Origination',
          'Test Loan Origination'
        ],
        Property: '9991 Warford Street Dawson, IA 50066',
        LoanNumber: 'QA4600468',
        Purpose: 'Purchase',
        Status: 'disclosure',
        CreatedDate: '2022-10-01T00:00:00',
        ClosingDate: "2024-03-24T17:51:06.137",
        ClientId: 132,
        ClientCode: 'EMPW'
      },
      {
        SessionId: 'A4470C0C-8259-4901-B25F-531F36FF835C',
        SignerNames: [
          'ALICE FIRSTIMER'
        ],
        Property: '10528 Columbia Ave Bratenahl, OH 44108',
        LoanNumber: '6027313993',
        Purpose: 'Purchase',
        Status: 'disclosure',
        CreatedDate: '2022-09-01T00:00:00',
        ClosingDate: "2024-03-24T17:51:06.137",
        ClientId: 103,
        ClientCode: 'C522'
      },
      {
        SessionId: '7A61A02F-5488-421C-8A9F-E32B2B8D9C3D',
        SignerNames: [
          'Suzi Builder'
        ],
        Property: 'Main street 3 Louisville, KY 40207',
        LoanNumber: '600011973',
        Purpose: 'Purchase',
        Status: 'disclosure',
        CreatedDate: '2022-09-01T00:00:00',
        ClosingDate: "2024-03-24T17:51:06.137",
        ClientId: 25,
        ClientCode: 'A986'
      },
      {
        SessionId: 'FE951688-7D36-4EA6-A6CE-5541E2B5DA81',
        SignerNames: [
          'SUZI BUILDER',
          'EVANGELINE S'
        ],
        Property: 'Main street 3 Louisville, KY 40207',
        LoanNumber: '600011973',
        Purpose: 'Purchase',
        Status: 'disclosure',
        CreatedDate: '2022-09-01T00:00:00',
        ClosingDate: "2024-03-24T17:51:06.137",
        ClientId: 25,
        ClientCode: 'A986'
      },
      {
        SessionId: '4FE830F4-33A4-4338-85E7-54306EA95530',
        SignerNames: [
          'ALICE PF FIRSTIMER',
          'ABC MORTGAGE'
        ],
        Property: '10655 Birch St 1-Sep-2022 12:58:38 Burbank, CA 91502-1234',
        LoanNumber: '6160320914',
        Purpose: 'Purchase',
        Status: 'disclosure',
        CreatedDate: '2022-09-01T00:00:00',
        ClosingDate: "2024-03-24T17:51:06.137",
        ClientId: 1005,
        ClientCode: 'C704'
      }
    ],
    error: null,
    page: 1,
    hasNextPage: true,
    isNextPageLoading: false,
    totalCount: 26668
  },
  {
    label: 'Pre Disclosure',
    value: 'predisclosure',
    loaded: true,
    checked: true,
    data: [
      {
        SessionId: '62EE4650-8820-4A1F-89DE-A66315ABCA32',
        SignerNames: [
          'LISA MCDOWELL',
          'PATRICIA ZANNIE'
        ],
        Property: 'TBD Denver, CO 80022',
        LoanNumber: '013702226',
        Purpose: 'Purchase',
        Status: 'pre_disclosure',
        CreatedDate: '2022-09-01T00:00:00',
        ClosingDate: "2024-03-24T17:51:06.137",
        ClientId: 898,
        ClientCode: 'C581'
      },
      {
        SessionId: '31D4476C-18CF-4A48-82E5-D5D5399350C1',
        SignerNames: [
          'LISA MCDOWELL',
          'PATRICIA ZANNIE'
        ],
        Property: 'TBD Denver, CO 80022',
        LoanNumber: '013702214',
        Purpose: 'Purchase',
        Status: 'pre_disclosure',
        CreatedDate: '2022-09-01T00:00:00',
        ClosingDate: "2024-03-24T17:51:06.137",
        ClientId: 898,
        ClientCode: 'C581'
      },
      {
        SessionId: '698DEB17-E20C-41F4-8B90-123CEDEB7EA3',
        SignerNames: [
          'TRACY L SODDERS'
        ],
        Property: 'TBD GAINESVILLE, FL 32601',
        LoanNumber: '013702229',
        Purpose: 'Purchase',
        Status: 'pre_disclosure',
        CreatedDate: '2022-09-01T00:00:00',
        ClosingDate: "2024-03-24T17:51:06.137",
        ClientId: 898,
        ClientCode: 'C581'
      },
      {
        SessionId: '8F25ACA1-FDBD-4A33-87EB-588A2D02188B',
        SignerNames: [
          'LISA MCDOWELL',
          'PATRICIA ZANNIE'
        ],
        Property: 'TBD Denver, CO 80022',
        LoanNumber: '013702214',
        Purpose: 'Purchase',
        Status: 'pre_disclosure',
        CreatedDate: '2022-09-01T00:00:00',
        ClosingDate: "2024-03-24T17:51:06.137",
        ClientId: 898,
        ClientCode: 'C581'
      },
      {
        SessionId: '860DAF5B-DB71-425B-81D3-C43475DC1E62',
        SignerNames: [
          'ALICE FIRSTIMER',
          'ALICE FREDDIE'
        ],
        Property: '54 Street GHDF Burbank, CA 91502',
        LoanNumber: 'CVRC0122090249',
        Purpose: 'No Cash Out Other',
        Status: 'pre_disclosure',
        CreatedDate: '2022-09-01T00:00:00',
        ClosingDate: "2024-03-24T17:51:06.137",
        ClientId: 920,
        ClientCode: 'C583'
      },
      {
        SessionId: 'A3A88CFA-108B-405A-A1FD-286BE334EED5',
        SignerNames: [
          'JABARI C BARNABY',
          'ERNEST M MOORE'
        ],
        Property: 'TBD CANYON, TX 79015',
        LoanNumber: '013702251',
        Purpose: 'Purchase',
        Status: 'pre_disclosure',
        CreatedDate: '2022-09-01T00:00:00',
        ClosingDate: "2024-03-24T17:51:06.137",
        ClientId: 898,
        ClientCode: 'C581'
      },
      {
        SessionId: 'BEDBCF28-D384-425B-BEA3-736E3B8AD12B',
        SignerNames: [
          'JABARI C BARNABY',
          'ERNEST M MOORE'
        ],
        Property: 'TBD CANYON, TX 79015',
        LoanNumber: '013702251',
        Purpose: 'Purchase',
        Status: 'pre_disclosure',
        CreatedDate: '2022-09-01T00:00:00',
        ClosingDate: "2024-03-24T17:51:06.137",
        ClientId: 898,
        ClientCode: 'C581'
      },
      {
        SessionId: 'F58DF353-1E6A-484F-810A-F88D77839CC0',
        SignerNames: [
          'JAIME WILLIAMS'
        ],
        Property: 'TBD santaquin, UT 84655',
        LoanNumber: '013692122',
        Purpose: 'Purchase',
        Status: 'pre_disclosure',
        CreatedDate: '2022-09-01T00:00:00',
        ClosingDate: "2024-03-24T17:51:06.137",
        ClientId: 898,
        ClientCode: 'C581'
      },
      {
        SessionId: 'F7B6A19A-57B9-4E3B-AF2B-93DB7C4D0714',
        SignerNames: [
          'Jaime WILLIAMS'
        ],
        Property: 'TBD Pearl City, HI 96782',
        LoanNumber: '013448863',
        Purpose: 'Purchase',
        Status: 'pre_disclosure',
        CreatedDate: '2022-09-01T00:00:00',
        ClosingDate: "2024-03-24T17:51:06.137",
        ClientId: 898,
        ClientCode: 'C581'
      },
      {
        SessionId: '53ECA088-DC3A-492B-B655-3533A0D69D07',
        SignerNames: [
          'Jaime WILLIAMS'
        ],
        Property: 'TBD Pearl City, HI 96782',
        LoanNumber: '013448863',
        Purpose: 'Purchase',
        Status: 'pre_disclosure',
        CreatedDate: '2022-09-01T00:00:00',
        ClosingDate: "2024-03-24T17:51:06.137",
        ClientId: 898,
        ClientCode: 'C581'
      }
    ],
    error: null,
    page: 1,
    hasNextPage: true,
    isNextPageLoading: false,
    totalCount: 2883
  },
  {
    label: 'Processing',
    value: 'processing',
    loaded: true,
    checked: true,
    data: [
      {
        SessionId: '8AA4DB6D-EB91-4C1F-A874-BFCE2D8974E3',
        SignerNames: [
          'Alice Firstimer'
        ],
        Property: '5678 Jacque HomeOne Plano, TX 75075',
        LoanNumber: '2089951',
        Purpose: 'Purchase',
        Status: 'processing',
        CreatedDate: '2022-09-01T00:00:00',
        ClosingDate: "2024-03-24T17:51:06.137",
        ClientId: 982,
        ClientCode: 'C651'
      },
      {
        SessionId: '03556747-17C6-42B2-82FC-27C9C1C62D5F',
        SignerNames: [
          'ALICE FIRSTIMER',
          'AMY AMERICA'
        ],
        Property: '6781 Moselle Dr San Jose, CA 95119',
        LoanNumber: '2801559259',
        Purpose: 'Purchase',
        Status: 'processing',
        CreatedDate: '2022-08-30T00:00:00',
        ClosingDate: "2024-03-24T17:51:06.137",
        ClientId: 88,
        ClientCode: 'C473'
      },
      {
        SessionId: '99A3378D-C81C-4640-AF30-1C339185FA6C',
        SignerNames: [
          'ALICE FIRSTIMER',
          'AMY AMERICA'
        ],
        Property: '123 subject test Eleele, HI 96705',
        LoanNumber: '2801559267',
        Purpose: 'Purchase',
        Status: 'processing',
        CreatedDate: '2022-08-30T00:00:00',
        ClosingDate: "2024-03-24T17:51:06.137",
        ClientId: 88,
        ClientCode: 'C473'
      },
      {
        SessionId: '63635D95-307D-4526-98B2-02D87E059C05',
        SignerNames: [
          'ALICE FIRSTIMER',
          'AMY AMERICA'
        ],
        Property: '123 subject test Hollister, ID 83301',
        LoanNumber: '2801559275',
        Purpose: 'Purchase',
        Status: 'processing',
        CreatedDate: '2022-08-30T00:00:00',
        ClosingDate: "2024-03-24T17:51:06.137",
        ClientId: 88,
        ClientCode: 'C473'
      },
      {
        SessionId: '04D03E18-C9E2-47EA-97FC-FBE7ED590892',
        SignerNames: [
          'ALICE FIRSTIMER'
        ],
        Property: '786 Test Denver, CO 80201',
        LoanNumber: '2801559143',
        Purpose: 'Purchase',
        Status: 'processing',
        CreatedDate: '2022-08-30T00:00:00',
        ClosingDate: "2024-03-24T17:51:06.137",
        ClientId: 88,
        ClientCode: 'C473'
      },
      {
        SessionId: 'F5BF2778-49AB-42E8-87A1-239D709B7A0F',
        SignerNames: [
          'Alice A Firstimer'
        ],
        Property: '3655 S TOWER CIR APT 12 ascendas Fayetteville, AR 72704-5776',
        LoanNumber: '2091445',
        Purpose: 'Purchase',
        Status: 'processing',
        CreatedDate: '2022-08-30T00:00:00',
        ClosingDate: "2024-03-24T17:51:06.137",
        ClientId: 982,
        ClientCode: 'C651'
      },
      {
        SessionId: '0CF2D09B-9C4F-43F3-A81C-B0185721CC1C',
        SignerNames: [
          'Alice Firstimer',
          'Test Loan Origination',
          'Test Loan Origination'
        ],
        Property: '9991 Warford Street Dawson, IA 50066',
        LoanNumber: 'QA4605106',
        Purpose: 'Purchase',
        Status: 'processing',
        CreatedDate: '2022-08-30T00:00:00',
        ClosingDate: "2024-03-24T17:51:06.137",
        ClientId: 132,
        ClientCode: 'EMPW'
      },
      {
        SessionId: 'D93962E4-9CEA-460F-A6A9-C81F2260D528',
        SignerNames: [
          'ALICE FIRSTIMER'
        ],
        Property: '58623 Cummins Dr Eureka, CA 95501',
        LoanNumber: '2801559473',
        Purpose: 'Purchase',
        Status: 'processing',
        CreatedDate: '2022-08-30T00:00:00',
        ClosingDate: "2024-03-24T17:51:06.137",
        ClientId: 88,
        ClientCode: 'C473'
      },
      {
        SessionId: '0ECA7CF4-1873-4202-B9CC-FBBB65ADA33F',
        SignerNames: [
          'ALICE FIRSTIMER'
        ],
        Property: '58623 Cummins Dr Eureka, CA 95501',
        LoanNumber: '2801559473',
        Purpose: 'Purchase',
        Status: 'processing',
        CreatedDate: '2022-08-30T00:00:00',
        ClosingDate: "2024-03-24T17:51:06.137",
        ClientId: 88,
        ClientCode: 'C473'
      },
      {
        SessionId: '5157E3E5-4059-417E-9565-422F9A55E75E',
        SignerNames: [
          'Alice A Firstimer'
        ],
        Property: '3655 S TOWER CIR APT 12 ascendas Fayetteville, AR 72704-5776',
        LoanNumber: '2091445',
        Purpose: 'Purchase',
        Status: 'processing',
        CreatedDate: '2022-08-30T00:00:00',
        ClosingDate: "2024-03-24T17:51:06.137",
        ClientId: 982,
        ClientCode: 'C651'
      }
    ],
    error: null,
    page: 1,
    hasNextPage: true,
    isNextPageLoading: false,
    totalCount: 404
  },
  {
    label: 'Shipping',
    value: 'shipping',
    loaded: true,
    checked: true,
    data: [
      {
        SessionId: '53C68256-9887-4928-B8E8-E9C20E79D10C',
        SignerNames: [
          'Alice Firstimer'
        ],
        Property: '1260 Duke Memorial Road Spring Hope, NC 27882',
        LoanNumber: '2100014872',
        Purpose: 'Purchase',
        Status: 'shipping',
        CreatedDate: '2022-08-31T00:00:00',
        ClosingDate: "2024-03-24T17:51:06.137",
        ClientId: 114,
        ClientCode: 'C557'
      },
      {
        SessionId: 'CE80BBAC-C8C6-4F35-8A94-228DBB9E670B',
        SignerNames: [
          'ALICE FIRSTIMER'
        ],
        Property: '20109 SHERRI LN Cleveland, OH 44114',
        LoanNumber: '9100048446',
        Purpose: 'Purchase',
        Status: 'shipping',
        CreatedDate: '2022-08-31T00:00:00',
        ClosingDate: "2024-03-24T17:51:06.137",
        ClientId: 114,
        ClientCode: 'C557'
      },
      {
        SessionId: '9AB3A3D5-AC89-49F5-A451-524E2F37E7C4',
        SignerNames: [
          'ALICE FIRSTIMER'
        ],
        Property: '111 TX Cash out FLAGSTAR JUMBO Houston, TX 77001',
        LoanNumber: '2801559341',
        Purpose: 'Cash Out Other',
        Status: 'shipping',
        CreatedDate: '2022-08-30T00:00:00',
        ClosingDate: "2024-03-24T17:51:06.137",
        ClientId: 88,
        ClientCode: 'C473'
      },
      {
        SessionId: '0D1E24A9-0D8D-4ECD-8A82-30452BA96E17',
        SignerNames: [
          'ALICE FIRSTIMER'
        ],
        Property: '20109 SHERRI LN Fort Dodge, IA 50501',
        LoanNumber: '9100048433',
        Purpose: 'Purchase',
        Status: 'shipping',
        CreatedDate: '2022-08-30T00:00:00',
        ClosingDate: "2024-03-24T17:51:06.137",
        ClientId: 114,
        ClientCode: 'C557'
      },
      {
        SessionId: '9F7DD37A-DAA5-46FC-B9B6-7236AE66F8D6',
        SignerNames: [
          'ALICE FIRSTIMER'
        ],
        Property: '20109 SHERRI LN Patterson Heights, PA 15010',
        LoanNumber: '9100048436',
        Purpose: 'No Cash Out Other',
        Status: 'shipping',
        CreatedDate: '2022-08-30T00:00:00',
        ClosingDate: "2024-03-24T17:51:06.137",
        ClientId: 114,
        ClientCode: 'C557'
      },
      {
        SessionId: '76487E79-E5D8-4691-A5F5-BF214827E924',
        SignerNames: [
          'ALICE FIRSTIMER'
        ],
        Property: '20109 SHERRI LN Cleveland, OH 44114',
        LoanNumber: '9100048430',
        Purpose: 'Purchase',
        Status: 'shipping',
        CreatedDate: '2022-08-30T00:00:00',
        ClosingDate: "2024-03-24T17:51:06.137",
        ClientId: 114,
        ClientCode: 'C557'
      },
      {
        SessionId: '323FE09A-C8A0-4168-A160-54AC9C501CB5',
        SignerNames: [
          'ALICE FIRSTIMER'
        ],
        Property: '20109 SHERRI LN Richmond, VA 23294',
        LoanNumber: '9100048434',
        Purpose: 'No Cash Out Other',
        Status: 'shipping',
        CreatedDate: '2022-08-30T00:00:00',
        ClosingDate: "2024-03-24T17:51:06.137",
        ClientId: 114,
        ClientCode: 'C557'
      },
      {
        SessionId: 'D89AB558-CBE0-4AEE-8B4E-406B7761AE51',
        SignerNames: [
          'LOCO MOTION'
        ],
        Property: '00345 Yankee Point Rd. San Jose, CA 95129',
        LoanNumber: '9100048423',
        Purpose: 'Cash Out Other',
        Status: 'shipping',
        CreatedDate: '2022-08-29T00:00:00',
        ClosingDate: "2024-03-24T17:51:06.137",
        ClientId: 114,
        ClientCode: 'C557'
      },
      {
        SessionId: '1FE7CB9C-18CE-47B7-806D-D6E8A7EFC25B',
        SignerNames: [
          'ALICE FIRSTIMER'
        ],
        Property: '9991 Warford Street Dawson, IA 50066',
        LoanNumber: '9100048412',
        Purpose: 'Cash Out Other',
        Status: 'shipping',
        CreatedDate: '2022-08-29T00:00:00',
        ClosingDate: "2024-03-24T17:51:06.137",
        ClientId: 114,
        ClientCode: 'C557'
      },
      {
        SessionId: '0864D37F-3115-4B98-BFCA-EC64DCD06E7D',
        SignerNames: [
          'Alice Firstimer'
        ],
        Property: '1260 Duke Memorial Road Spring Hope, NC 27882',
        LoanNumber: '2100014846',
        Purpose: 'Purchase',
        Status: 'shipping',
        CreatedDate: '2022-08-29T00:00:00',
        ClosingDate: "2024-03-24T17:51:06.137",
        ClientId: 114,
        ClientCode: 'C557'
      }
    ],
    error: null,
    page: 1,
    hasNextPage: true,
    isNextPageLoading: false,
    totalCount: 1227
  }
]

export const mockAllSessionsData = [
  {
    label: 'all',
    value: 'pre_disclosure,disclosure,redisclosure,tla_redisclosure,adverse_action,appraisal,processing,underwriting,preclosing,closing,shipping',
    loaded: true,
    checked: true,
    data: [
      {
        SessionId: '2CD9E5F1-9517-488A-B7EE-698B8922BB12',
        SignerNames: [
          {
            FullName: 'BORROWER1FIRST BORROWER1LAST',
            HasSigned: true
          },
          {
            FullName: 'BORROWER2FIRST BORROWER2LAST',
            HasSigned: true
          },
          {
            FullName: 'GEORGE TITLE',
            HasSigned: false
          }
        ],
        Property: '1000 N Main St Clearwater, FL 96910',
        LoanNumber: 'JB_Test_0327_01',
        Purpose: 'Purchase',
        Status: 'Disclosure',
        CreatedDate: '2023-03-27T00:00:00',
        ClosingDate: '2023-03-30T00:00:00',
        ClientId: 1109,
        ClientCode: 'PKTM'
      },
      {
        SessionId: '5A540DF9-32F7-4942-A3DB-427BCC838205',
        SignerNames: [
          {
            FullName: 'ARTÍCULOS APPLE',
            HasSigned: false
          },
          {
            FullName: 'BRAVO BANNANA',
            HasSigned: false
          },
          {
            FullName: 'CHARLIE COCONUT',
            HasSigned: false
          },
          {
            FullName: 'DELTA DATE',
            HasSigned: false
          }
        ],
        Property: '6 DT 15YR Conv Scenario Phinizy, GA 30802',
        LoanNumber: '4olotestTESTSOLEX_1',
        Purpose: 'Purchase',
        Status: 'Preclosing',
        CreatedDate: '2023-03-24T00:00:00',
        ClosingDate: '2023-03-27T00:00:00',
        ClientId: 937,
        ClientCode: 'CFTM'
      },
      {
        SessionId: '063FF1E8-F99F-4A4F-8901-2C624A155046',
        SignerNames: [
          {
            FullName: 'Joshua Sales',
            HasSigned: false
          },
          {
            FullName: 'Settlement Agent',
            HasSigned: false
          }
        ],
        Property: '1000 N WICKHAM RD R Helena, AL 35020',
        LoanNumber: '20230321eCloseTest_1',
        Purpose: 'Purchase',
        Status: 'Closing',
        CreatedDate: '2023-03-21T00:00:00',
        ClosingDate: '2023-03-21T00:00:00',
        ClientId: 31,
        ClientCode: 'BCTM'
      },
      {
        SessionId: 'DCBDB97F-7ECC-4A10-B4F6-6337DB8C1629',
        SignerNames: [
          {
            FullName: 'ARTÍCULOS APPLE',
            HasSigned: false
          },
          {
            FullName: 'BRAVO BANNANA',
            HasSigned: false
          },
          {
            FullName: 'CHARLIE COCONUT',
            HasSigned: false
          },
          {
            FullName: 'DELTA DATE',
            HasSigned: false
          }
        ],
        Property: '6 DT 15YR Conv Scenario Phinizy, GA 30802',
        LoanNumber: '4olotestTESTSOLEX_64',
        Purpose: 'Purchase',
        Status: 'Preclosing',
        CreatedDate: '2023-03-17T00:00:00',
        ClosingDate: '2023-03-27T00:00:00',
        ClientId: 937,
        ClientCode: 'CFTM'
      },
      {
        SessionId: 'E84D403A-F4A2-44D8-AE94-6A7C90C9D1F2',
        SignerNames: [
          {
            FullName: 'BRAVO BANNANA',
            HasSigned: false
          },
          {
            FullName: 'CHARLIE COCONUT',
            HasSigned: false
          },
          {
            FullName: 'DELTA DATE',
            HasSigned: false
          }
        ],
        Property: '6 DT 15YR Conv Scenario Phinizy, GA 30802',
        LoanNumber: '4olotestTESTSOLEX_65',
        Purpose: 'Purchase',
        Status: 'Preclosing',
        CreatedDate: '2023-03-17T00:00:00',
        ClosingDate: '2023-03-27T00:00:00',
        ClientId: 937,
        ClientCode: 'CFTM'
      },
      {
        SessionId: '9D7661B8-DA60-4AF3-B3F3-9FF72A5A2FF6',
        SignerNames: [
          {
            FullName: 'BRAVO BANNANA',
            HasSigned: false
          },
          {
            FullName: 'CHARLIE COCONUT',
            HasSigned: false
          },
          {
            FullName: 'DELTA DATE',
            HasSigned: false
          }
        ],
        Property: '6 DT 15YR Conv Scenario Phinizy, GA 30802',
        LoanNumber: '4olotestTESTSOLEX_66',
        Purpose: 'Purchase',
        Status: 'Preclosing',
        CreatedDate: '2023-03-17T00:00:00',
        ClosingDate: '2023-03-27T00:00:00',
        ClientId: 937,
        ClientCode: 'CFTM'
      },
      {
        SessionId: '83567AA4-3C44-4C16-AB61-C2C818809AE9',
        SignerNames: [
          {
            FullName: 'BRAVO BANNANA',
            HasSigned: false
          },
          {
            FullName: 'CHARLIE COCONUT',
            HasSigned: false
          },
          {
            FullName: 'DELTA DATE',
            HasSigned: false
          }
        ],
        Property: '6 DT 15YR Conv Scenario Phinizy, GA 30802',
        LoanNumber: '4olotestTESTSOLEX_67',
        Purpose: 'Purchase',
        Status: 'Preclosing',
        CreatedDate: '2023-03-17T00:00:00',
        ClosingDate: '2023-03-27T00:00:00',
        ClientId: 937,
        ClientCode: 'CFTM'
      },
      {
        SessionId: 'CC3A51DF-3140-42DC-A6B3-05A0E3A9B10B',
        SignerNames: [
          {
            FullName: 'BRAVO BANNANA',
            HasSigned: false
          },
          {
            FullName: 'CHARLIE COCONUT',
            HasSigned: false
          },
          {
            FullName: 'DELTA DATE',
            HasSigned: false
          }
        ],
        Property: '6 DT 15YR Conv Scenario Phinizy, GA 30802',
        LoanNumber: '4olotestTESTSOLEX_70',
        Purpose: 'Purchase',
        Status: 'Preclosing',
        CreatedDate: '2023-03-17T00:00:00',
        ClosingDate: '2023-03-27T00:00:00',
        ClientId: 937,
        ClientCode: 'CFTM'
      },
      {
        SessionId: '21D797AC-D3E4-478B-827A-4FC5C4C79A52',
        SignerNames: [
          {
            FullName: 'ARTÍCULOS APPLE',
            HasSigned: false
          }
        ],
        Property: '6 DT 15YR Conv Scenario Phinizy, GA 30802',
        LoanNumber: '4olotestTESTSOLEX_70',
        Purpose: 'Purchase',
        Status: 'Preclosing',
        CreatedDate: '2023-03-17T00:00:00',
        ClosingDate: '2023-03-27T00:00:00',
        ClientId: 937,
        ClientCode: 'CFTM'
      },
      {
        SessionId: 'F12E6F86-1C22-4CB5-B0C3-63866844BC32',
        SignerNames: [
          {
            FullName: 'ARTÍCULOS APPLE',
            HasSigned: false
          }
        ],
        Property: '6 DT 15YR Conv Scenario Phinizy, GA 30802',
        LoanNumber: '4olotestTESTSOLEX_70',
        Purpose: 'Purchase',
        Status: 'Preclosing',
        CreatedDate: '2023-03-17T00:00:00',
        ClosingDate: '2023-03-27T00:00:00',
        ClientId: 937,
        ClientCode: 'CFTM'
      }
    ],
    error: null,
    page: 1,
    hasNextPage: true,
    isNextPageLoading: false,
    totalCount: 140
  }
]

export const mockDisclosureData = {
  Sessions: [
      {
          SessionId: "4f2e622e-ed89-4c18-bef2-7902256c0532",
          SignerNames: [
              {
                  FullName: "TEDDY SMITH",
                  HasSigned: false
              },
              {
                  FullName: "DOORTHY SMITH",
                  HasSigned: false
              }
          ],
          Property: "1000 N Main St, Clearwater, FL 96910",
          LoanNumber: "Test_KP_SXV_1003_01",
          Purpose: "Purchase",
          Status: "disclosure",
          CreatedDate: "2023-10-03T17:52:17",
          ClosingDate: "2023-10-30T00:00:00",
          ClientId: 1,
          ClientCode: "PKTM",
          LoanOfficerName: "Kat Testing",
          LoanOfficerEmail: "kpetrik@firstam.com"
      },
      {
          SessionId: "d76ee32b-c30f-4f20-9d58-f4db90a5349a",
          SignerNames: [
              {
                  FullName: "TEDDY SMITH",
                  HasSigned: false
              },
              {
                  FullName: "DOORTHY SMITH",
                  HasSigned: false
              }
          ],
          Property: "1111 Main St, Houston, TX 77001",
          LoanNumber: "Test_PK_SXV_0927_01",
          Purpose: "Purchase",
          Status: "disclosure",
          CreatedDate: "2023-09-27T14:38:21",
          ClosingDate: "2023-09-30T00:00:00",
          ClientId: 1,
          ClientCode: "PKTM",
          LoanOfficerName: "Jason Jackson",
          LoanOfficerEmail: "SolexVisionTestLO@docutech.com"
      },
      {
          SessionId: "0ab2498f-e567-4a05-b140-0008121343f9",
          SignerNames: [
              {
                  FullName: "TEDDY SMITH",
                  HasSigned: false
              },
              {
                  FullName: "DOORTHY SMITH",
                  HasSigned: false
              }
          ],
          Property: "1111 Main St, Houston, TX 77001",
          LoanNumber: "Test_PK_SXV_0919_01",
          Purpose: "Purchase",
          Status: "disclosure",
          CreatedDate: "2023-09-19T17:36:11",
          ClosingDate: "2023-09-30T00:00:00",
          ClientId: 1,
          ClientCode: "PKTM",
          LoanOfficerName: "Jason Jackson",
          LoanOfficerEmail: "SolexVisionTestLO@docutech.com"
      },
      {
          SessionId: "242519ca-15e6-453a-b82c-0fe1a9f9f16c",
          SignerNames: [
              {
                  FullName: "TEDDY SMITH",
                  HasSigned: false
              },
              {
                  FullName: "DOORTHY SMITH",
                  HasSigned: false
              }
          ],
          Property: "1000 N Main St, Clearwater, FL 96910",
          LoanNumber: "Test_KP_SXV_0919_03",
          Purpose: "Purchase",
          Status: "disclosure",
          CreatedDate: "2023-09-19T17:32:38",
          ClosingDate: "2023-09-30T00:00:00",
          ClientId: 1,
          ClientCode: "PKTM",
          LoanOfficerName: "Kat Testing",
          LoanOfficerEmail: "kpetrik@firstam.com"
      },
      {
          SessionId: "1505e251-c81b-413d-bfc2-91a46fd57276",
          SignerNames: [
              {
                  FullName: "TEDDY SMITH",
                  HasSigned: false
              },
              {
                  FullName: "DOORTHY SMITH",
                  HasSigned: false
              }
          ],
          Property: "1000 N Main St, Clearwater, FL 96910",
          LoanNumber: "Test_KP_SXV_0919_02",
          Purpose: "Purchase",
          Status: "disclosure",
          CreatedDate: "2023-09-19T17:26:33",
          ClosingDate: "2023-10-08T00:00:00",
          ClientId: 1,
          ClientCode: "PKTM",
          LoanOfficerName: " ",
          LoanOfficerEmail: "kpetrik@firstam.com"
      },
      {
          SessionId: "6d6ebf6e-d235-4137-9705-0097376293cb",
          SignerNames: [
              {
                  FullName: "TEDDY SMITH",
                  HasSigned: false
              },
              {
                  FullName: "DOORTHY SMITH",
                  HasSigned: false
              }
          ],
          Property: "1000 N Main St, Clearwater, FL 96910",
          LoanNumber: "Test_KP_SXV_0908_01",
          Purpose: "Purchase",
          Status: "disclosure",
          CreatedDate: "2023-09-08T13:48:52",
          ClosingDate: "2023-10-08T00:00:00",
          ClientId: 1,
          ClientCode: "PKTM",
          LoanOfficerName: " ",
          LoanOfficerEmail: "kpetrik@firstam.com"
      },
      {
          SessionId: "bef825a9-b786-4560-920f-f24094e9cceb",
          SignerNames: [
              {
                  FullName: "TEDDY SMITH",
                  HasSigned: false
              },
              {
                  FullName: "DOORTHY SMITH",
                  HasSigned: false
              }
          ],
          Property: "1000 N Main St, Clearwater, FL 96910",
          LoanNumber: "Test_KP_SXV_0907_01",
          Purpose: "Purchase",
          Status: "disclosure",
          CreatedDate: "2023-09-07T18:39:52",
          ClosingDate: "2023-10-08T00:00:00",
          ClientId: 1,
          ClientCode: "PKTM",
          LoanOfficerName: " ",
          LoanOfficerEmail: "kpetrik@firstam.com"
      },
      {
          SessionId: "8596bb24-3a28-4f72-b7a8-2ade94b20459",
          SignerNames: [],
          Property: "1000 N Main St, Clearwater, FL 96910",
          LoanNumber: "Test_KP_SXV_0906_02",
          Purpose: "Purchase",
          Status: "disclosure",
          CreatedDate: "2023-09-06T17:53:53",
          ClosingDate: "2023-09-30T00:00:00",
          ClientId: 1,
          ClientCode: "PKTM",
          LoanOfficerName: " ",
          LoanOfficerEmail: "testuser4@sxvs.com"
      },
      {
          SessionId: "2fc08b5b-290e-40ee-a019-6da0f3f89b3d",
          SignerNames: [],
          Property: "1000 N Main St, Clearwater, FL 96910",
          LoanNumber: "Test_KP_SXV_0906_01",
          Purpose: "Purchase",
          Status: "disclosure",
          CreatedDate: "2023-09-06T15:04:11",
          ClosingDate: "2023-09-30T00:00:00",
          ClientId: 1,
          ClientCode: "PKTM",
          LoanOfficerName: " ",
          LoanOfficerEmail: "testuser4@sxvs.com"
      },
      {
          SessionId: "58aa2633-5354-4653-89b4-f061bc85db15",
          SignerNames: [
              {
                  FullName: "TEDDY SMITH",
                  HasSigned: true
              },
              {
                  FullName: "DOORTHY SMITH",
                  HasSigned: false
              }
          ],
          Property: "1000 N Main St, Clearwater, FL 96910",
          LoanNumber: "Test_KP_SXV_0831_02",
          Purpose: "Purchase",
          Status: "disclosure",
          CreatedDate: "2023-08-31T18:06:34",
          ClosingDate: "2023-09-30T00:00:00",
          ClientId: 1,
          ClientCode: "PKTM",
          LoanOfficerName: " ",
          LoanOfficerEmail: "SolexVisionTestLO@docutech.com"
      }
  ],
  TotalCount: 18
}
