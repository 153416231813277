export enum DocDownloadType {
    Individual = 'individual',
    MergedDocs = 'merged',
    AuditLog = 'audit-log'
}

export enum PackageStatusType {
    Pending    = 'Pending',
    ReadyForTagging = 'ReadyForTagging',
    Open = 'Open',
    InProgress = 'InProgress',
    Cancelled = 'Cancelled',
    Expired = 'Expired',
    Complete = 'Complete',
    Archived = 'Archived',
    Failed = 'Failed'
}
/**
 * NOTE: How roles are evaluated
 * Edit All > Edit > Read All > Read > None
 */
export enum OrdersRoles {
    LoanOfficer = 'sxvs.loanofficer',
    Admin = 'sxvs.admin',
    Officer = 'sxvs.officer',
    Edit = 'sxvs.edit',
    EditAll = 'sxvs.edit.all',
    Read = 'sxvs.read',
    ReadAll = 'sxvs.read.all',
}

export enum StepperActions {
    Cancel = 'cancel',
    Update = 'update',
    Submit = 'submit',
    Edit = 'edit',
    UploadFile = 'upload-file',
    RemoveFile = 'remove-file',
    ReorderList = 'reorder-list'
}
