/**
 * Enum for Dashboard Layout values
 * @enum {DashboardLayout}
 */
export enum DashboardLayout {
  /**
   * @type {string}
   */
  Rows = 'rows',
  /**
   * @type {string}
   */
  Columns = 'columns'
}
